var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CollectionPage',{ref:"collection",attrs:{"title":_vm.$t('nav.Graphs'),"query":_vm.query,"variables":{ translation: _vm.$i18n.locale, default: 'en-us' },"mappingFunction":function (data) {
      var input = data.allGraphInfo;
      return input.map(function (ele) {
        return {
          url: ("/graph/" + (_vm.$i18n.locale) + "/" + (ele.url)),
          categories: ele.categories,
          isAnchorPublished: ele.isPublished,
          title: ele.content.title,
          authors: ele.authors,
          modifiedTime: ele.modifiedTime,
          abstract: ele.content.abstract,
          isTransPublished: ele.content.isPublished,
        };
      });
    },"moreButtonText":_vm.$t('collectionPage.PlayWithIt'),"notClickableWhenNoContent":false}})}
var staticRenderFns = []

export { render, staticRenderFns }